<template>
  <div class="w100 h100">
    <!-- 测试 -->
    <div class="texts">
      运营提供文案运营提供文案运营提供文案运营提供
      文案运营提供文案运营提供文案运营提供文案
    </div>
    <div class="logo">
      <img src="@/assets/img/logo.png" class="logo" />
    </div>
    <div class="loginwx" @click="Toxcx">微信用户快捷登录</div>
    <div class="remark">
      登录即代表您阅读并同意舒昕逸家
      <a class="a">用户服务协议</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "jumpxcx",
  components: {},
  data() {
    return {};
  },
  methods: {
    Toxcx() {
      console.log("去小程序");
    },
  },
};
</script>

<style lang="less" scoped>
.texts {
  width: 316px;
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  padding-top: 100px;
  margin: 0 auto;
}
.logo {
  width: 138px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loginwx {
  width: 90%;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  background: #32a2d3;
  border-radius: 22px;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, 50%);
}
.remark {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.a {
  color: #333333;
  font-weight: 500;
}
</style>
